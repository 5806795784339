html{
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
@media (prefers-color-scheme: dark) {
  body {
    margin: 0;
    background-color: rgb(66, 66, 66);
    color: #fff;
  }
}
@media (prefers-color-scheme: light) {
  body {
    margin: 0;
    background-color: rgb(245, 245, 245);
    color: #000;
  }
}
.container {
  margin: 80px auto 0 auto;
  max-width: 1200px;
}
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}
